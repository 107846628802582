var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pa-3", attrs: { align: "left", justify: "center" } },
    [
      _c("Loading", {
        attrs: { active: _vm.loadingInicial, "is-full-page": true }
      }),
      _c(
        "v-card",
        { attrs: { align: "left", justify: "left" } },
        [
          _c(
            "v-card-text",
            { staticClass: "pa-3 font-weight-light white black--text" },
            [
              _c("v-icon", { staticClass: "primary--text lighten-2 pa-2" }, [
                _vm._v(" mdi-chart-box ")
              ]),
              _vm._v("Relatórios > Financeiro > Repasses e taxas ")
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card",
        {},
        [
          _c(
            "v-row",
            { staticClass: "mx-3 mt-3 py-4", attrs: { align: "center" } },
            [
              _c(
                "v-col",
                { staticClass: "px-1 py-3", attrs: { lg: "12" } },
                [
                  _c(
                    "v-card-text",
                    { staticClass: "pa-0" },
                    [
                      _c("v-icon", { staticClass: "mx-2" }, [
                        _vm._v("mdi-information-outline")
                      ]),
                      _vm._v(
                        "Utilize os campos abaixo para aplicar filtros na pesquisa, após selecionar clique em Exportar Resultados"
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "4", xs: "2", sm: "2", md: "3", lg: "3" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      clearable: "",
                      solo: "",
                      small: "",
                      hint: "Informe o Código do título",
                      "prepend-icon": "mdi-chevron-right",
                      label: "Código do título",
                      placeholder: "Titulo",
                      elevation: "0",
                      "hide-details": "auto"
                    },
                    on: {
                      keydown: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.filtrar()
                      }
                    },
                    model: {
                      value: _vm.filtros.ds_nosso_numero,
                      callback: function($$v) {
                        _vm.$set(_vm.filtros, "ds_nosso_numero", $$v)
                      },
                      expression: "filtros.ds_nosso_numero"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "4", xs: "2", sm: "2", md: "3", lg: "3" } },
                [
                  _c("v-text-field", {
                    directives: [
                      {
                        name: "mask",
                        rawName: "v-mask",
                        value: ["###.###.###-##", "XX.XXX.XXX/XXXX-##"],
                        expression: "['###.###.###-##', 'XX.XXX.XXX/XXXX-##']"
                      }
                    ],
                    attrs: {
                      clearable: "",
                      solo: "",
                      small: "",
                      hint: "Informe o CPF/CNPJ",
                      label: "CPF/CNPJ",
                      placeholder: "Documento",
                      "hide-details": "auto",
                      onkeypress:
                        "return event.charCode >= 46 && event.charCode <= 57"
                    },
                    on: {
                      keydown: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.filtrar()
                      }
                    },
                    model: {
                      value: _vm.filtros.nu_identificacao_devedor,
                      callback: function($$v) {
                        _vm.$set(_vm.filtros, "nu_identificacao_devedor", $$v)
                      },
                      expression: "filtros.nu_identificacao_devedor"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "4", xs: "2", sm: "2", md: "3", lg: "3" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      clearable: "",
                      solo: "",
                      small: "",
                      label: "Nome do devedor",
                      hint: "Informe o Nome do devedor",
                      placeholder: "Nome",
                      "hide-details": "auto"
                    },
                    on: {
                      keydown: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.filtrar()
                      }
                    },
                    model: {
                      value: _vm.filtros.nm_devedor,
                      callback: function($$v) {
                        _vm.$set(_vm.filtros, "nm_devedor", $$v)
                      },
                      expression: "filtros.nm_devedor"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "4", xs: "2", sm: "2", md: "3", lg: "3" } },
                [
                  _c(
                    "v-menu",
                    {
                      attrs: {
                        solo: "",
                        "close-on-content-click": false,
                        "nudge-right": 40,
                        transition: "scale-transition",
                        "offset-y": "",
                        "max-width": "290px",
                        "min-width": "290px"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function({ on }) {
                            return [
                              _c(
                                "v-text-field",
                                _vm._g(
                                  {
                                    attrs: {
                                      clearable: "",
                                      solo: "",
                                      "hide-details": "auto",
                                      value:
                                        _vm.computedDateAtualizacaoFormattedRange,
                                      label: "Data de Movimentação",
                                      "prepend-icon": "mdi-calendar-cursor",
                                      "persistent-hint": "",
                                      readonly: ""
                                    },
                                    on: {
                                      "click:clear": function($event) {
                                        return _vm.limparFiltroData()
                                      }
                                    }
                                  },
                                  on
                                )
                              )
                            ]
                          }
                        }
                      ]),
                      model: {
                        value: _vm.menuDataAtualizacaoRange,
                        callback: function($$v) {
                          _vm.menuDataAtualizacaoRange = $$v
                        },
                        expression: "menuDataAtualizacaoRange"
                      }
                    },
                    [
                      _c("v-date-picker", {
                        attrs: {
                          range: "",
                          "selected-items-text": "Datas Selecionadas",
                          color: "primary",
                          locale: "pt-br"
                        },
                        model: {
                          value: _vm.filtros.dt_movimento_range,
                          callback: function($$v) {
                            _vm.$set(_vm.filtros, "dt_movimento_range", $$v)
                          },
                          expression: "filtros.dt_movimento_range"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            {
              staticClass: "mx-3 mt-3 py-4",
              attrs: { align: "center", justify: "end" }
            },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-3",
                  attrs: {
                    color: "primary",
                    disabled: false,
                    loading: _vm.loadingFilter,
                    "data-cy": "btnFiltrarRelatorioRepasse"
                  },
                  on: {
                    click: function($event) {
                      return _vm.filtrar()
                    }
                  }
                },
                [
                  _vm._v(" Filtrar "),
                  _c("v-icon", { staticClass: "ml-2" }, [_vm._v("mdi-magnify")])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "mt-6" },
        [
          _c("v-data-table", {
            staticClass: "elevation-1 pa-3",
            attrs: {
              headers: _vm.headers,
              items: _vm.titulos,
              options: _vm.options,
              "server-items-length": _vm.totalTitulos,
              "footer-props": {
                itemsPerPageOptions: [10, 50, 100, 500, -1],
                itemsPerPageText: "Itens por página:"
              },
              loading: _vm.loadingTable,
              "loading-text": "Pesquisando títulos...",
              "no-data-text":
                "Nenhum resultado encontrado... Faça uma pesquisa",
              "no-results-text": "Nenhum resultado encontrado..."
            },
            on: {
              "update:options": function($event) {
                _vm.options = $event
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: `item.ds_nosso_numero`,
                  fn: function({ item }) {
                    return [
                      _c("span", { style: { color: "black" } }, [
                        _vm._v(_vm._s(item.ds_nosso_numero))
                      ])
                    ]
                  }
                },
                {
                  key: `item.dt_cadastro`,
                  fn: function({ item }) {
                    return [
                      _c("span", { style: { color: "black" } }, [
                        _vm._v(_vm._s(item.dt_cadastro))
                      ])
                    ]
                  }
                },
                {
                  key: `item.nu_identificacao_devedor`,
                  fn: function({ item }) {
                    return [
                      _c("span", { style: { color: "black" } }, [
                        _vm._v(_vm._s(_vm.formatDocumento(item)))
                      ])
                    ]
                  }
                },
                {
                  key: `item.nm_devedor`,
                  fn: function({ item }) {
                    return [
                      _c("span", { style: { color: "black" } }, [
                        _vm._v(_vm._s(item.nm_devedor))
                      ])
                    ]
                  }
                },
                {
                  key: `item.vl_saldo_protesto`,
                  fn: function({ item }) {
                    return [
                      _c("span", { style: { color: "black" } }, [
                        _vm._v(_vm._s(item.vl_saldo_protesto))
                      ])
                    ]
                  }
                },
                {
                  key: `item.vl_repassado`,
                  fn: function({ item }) {
                    return [
                      _c("span", { style: { color: "black" } }, [
                        _vm._v(
                          _vm._s(_vm.formatarDecimal(item.vl_repassado, false))
                        )
                      ])
                    ]
                  }
                },
                {
                  key: `item.cd_status`,
                  fn: function({ item }) {
                    return [
                      _c("span", { style: { color: "black" } }, [
                        _vm._v(_vm._s(_vm.getDescSituacao(item)))
                      ])
                    ]
                  }
                },
                {
                  key: `item.dt_atualizacao`,
                  fn: function({ item }) {
                    return [
                      _c("span", { style: { color: "black" } }, [
                        _vm._v(_vm._s(item.dt_atualizacao))
                      ])
                    ]
                  }
                },
                {
                  key: `item.dt_arquivo_movimento`,
                  fn: function({ item }) {
                    return [
                      _c("span", { style: { color: "black" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.formatarDataHoraMoment(
                              item.dt_arquivo_movimento
                            )
                          )
                        )
                      ])
                    ]
                  }
                }
              ],
              null,
              true
            )
          })
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "mt-3" },
        [
          _c(
            "v-row",
            { staticClass: "my-4 ml-4" },
            [
              _c(
                "v-col",
                { staticClass: "text-end", attrs: { lg: "12" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mx-6",
                      attrs: {
                        color: "primary",
                        disabled: !_vm.titulos.length,
                        loading: _vm.loadingExport,
                        "data-cy": "btnExportarRelatorioRepasse"
                      },
                      on: {
                        click: function($event) {
                          return _vm.exportar()
                        }
                      }
                    },
                    [
                      _vm._v(" Exportar resultados "),
                      _c(
                        "v-icon",
                        { staticClass: "ma-2", attrs: { small: "" } },
                        [_vm._v("cloud_download")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.snackbarDialog
        ? _c("Snackbar", {
            attrs: {
              mostrarSnackbar: _vm.snackbarDialog,
              corSnackbar: _vm.snackbarColor,
              mensagemSnackbar: _vm.mensagemRetornoSnackbar
            },
            on: {
              fecharSnackbar: function($event) {
                _vm.snackbarDialog = false
              }
            }
          })
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500" },
          model: {
            value: _vm.modalReceberRelatorioEmail,
            callback: function($$v) {
              _vm.modalReceberRelatorioEmail = $$v
            },
            expression: "modalReceberRelatorioEmail"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-text",
                [
                  _c(
                    "v-card-title",
                    { staticClass: "headline primary--text" },
                    [_vm._v("Relatório via e-mail")]
                  ),
                  _vm.filtros
                    ? _c("v-card-text", [
                        _vm._v(
                          " O relatórios será aplicado com os seguintes filtros: "
                        )
                      ])
                    : _vm._e(),
                  _vm.filtros.ds_numero_titulo != null
                    ? _c(
                        "v-card-text",
                        [
                          _c("v-icon", [_vm._v("mdi-information-outline")]),
                          _vm._v(
                            " Código do Título: " +
                              _vm._s(_vm.filtros.ds_numero_titulo) +
                              " "
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.filtros.nu_identificacao_devedor != null
                    ? _c(
                        "v-card-text",
                        [
                          _c("v-icon", [_vm._v("mdi-information-outline")]),
                          _vm._v(
                            " Documento do Devedor: " +
                              _vm._s(_vm.filtros.nu_identificacao_devedor) +
                              " "
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.filtros.nm_devedor != null
                    ? _c(
                        "v-card-text",
                        [
                          _c("v-icon", [_vm._v("mdi-information-outline")]),
                          _vm._v(
                            " Nome do Devedor: " +
                              _vm._s(_vm.filtros.nm_devedor) +
                              " "
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.filtros.cd_status != null
                    ? _c(
                        "v-card-text",
                        [
                          _c("v-icon", [_vm._v("mdi-information-outline")]),
                          _vm._v(
                            " Código do Status: " +
                              _vm._s(
                                _vm.getNomeCdStatus(_vm.filtros.cd_status)
                              ) +
                              " "
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.computedDateAtualizacaoFormattedRange != null
                    ? _c(
                        "v-card-text",
                        [
                          _c("v-icon", [_vm._v("mdi-information-outline")]),
                          _vm._v(
                            " Data de atualização dos envios: " +
                              _vm._s(
                                _vm.computedDateAtualizacaoFormattedRange
                              ) +
                              " "
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("v-spacer"),
                  _c("v-card-subtitle", [
                    _vm._v("Digite o e-mail onde deseja receber o relatório")
                  ]),
                  _c(
                    "v-form",
                    {
                      ref: "formEmail",
                      model: {
                        value: _vm.validEmail,
                        callback: function($$v) {
                          _vm.validEmail = $$v
                        },
                        expression: "validEmail"
                      }
                    },
                    [
                      _c("v-text-field", {
                        attrs: {
                          "prepend-icon": "person",
                          name: "email",
                          label: "Digite aqui seu e-mail",
                          type: "email",
                          solo: "",
                          rules: _vm.emailRules,
                          loading: _vm.loadingFiltros,
                          required: "",
                          "data-cy": "emailReportRepasse"
                        },
                        model: {
                          value: _vm.emailReport,
                          callback: function($$v) {
                            _vm.emailReport = $$v
                          },
                          expression: "emailReport"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        disabled: _vm.checkForm(),
                        "data-cy": "btnEnviarRelatorioRepasse"
                      },
                      on: {
                        click: function($event) {
                          return _vm.exportarRelatoriosMsgs()
                        }
                      }
                    },
                    [
                      _vm._v(" Enviar Relatório "),
                      _c("v-icon", { staticClass: "ml-1" }, [
                        _vm._v("chevron_right")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500" },
          model: {
            value: _vm.mostraConfirmacaoEmail,
            callback: function($$v) {
              _vm.mostraConfirmacaoEmail = $$v
            },
            expression: "mostraConfirmacaoEmail"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-text",
                [
                  _c(
                    "v-card-title",
                    { staticClass: "headline primary--text" },
                    [_vm._v("Relatório solicitado com sucesso.")]
                  ),
                  _c("v-spacer"),
                  _c("v-card-subtitle", [
                    _vm._v("O relatório será enviado para o seu e-mail!")
                  ])
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.mostraConfirmacaoEmail,
                          expression: "mostraConfirmacaoEmail"
                        }
                      ],
                      attrs: { color: "green darken-1", text: "" },
                      on: {
                        click: function($event) {
                          _vm.mostraConfirmacaoEmail = false
                        }
                      }
                    },
                    [_vm._v("Ok!")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }